<template>
  <div>
    <edit-block :state="state" :title="title" :AuditStatus="AuditStatus">
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <SupplementEdit
        @cancel="cancel"
        @save="finish"
        :rules="rules"
        :model="model"
        slot="editing"
      />
      <!-- 已填写 -->
      <SupplementDisplay :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div class="display-opt" slot="display-opt">
        <a-space @click="updateState(1)" class="cursor-pointer text-primary">
          <img src="@/assets/icons/modify.svg" alt="" />
          <span>修改</span>
        </a-space>
      </div>
    </edit-block>
  </div>
</template>

<script>
import SupplementEdit from "@/components/user/supplement/editing.vue";
import SupplementDisplay from "@/components/user/supplement/display.vue";

export default {
  components: { SupplementDisplay, SupplementEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    AuditStatus: Number,
    save: Function,
  },
  created() {
    this.model = this.transform(this.dataSource);
  },
  data() {
    return {
      title: "其他补充",
      model: {},
      rules: {},
      state: 0,
      options: [],
    };
  },
  methods: {
    transform({ Supplement }) {
      const temp = { Supplement };
      temp.Supplement = Supplement;
      this.updateState(this.getState(temp));
      // this.validate(temp);
      return temp;
    },
    restore(obj) {
      return { ...obj };
    },
    getState({ Supplement }) {
      if (Supplement) {
        return 2;
      }
      return 0;
    },
    assign(obj) {
      const temp = { ...obj },
        origin = { ...this.dataSource };

      return Object.assign({}, origin, temp);
    },
    validate({ Supplement }) {
      let status = true;
      status = !!Supplement;
      console.log(status, "supplement");
      this.$emit("update:status", status);
    },
    finish(data) {
      const temp = this.assign(this.restore(data));
      this.save(temp).then(() => {
        this.$message.success("保存成功");
        this.model = this.transform(temp);
      });
    },
    cancel() {
      this.updateState(this.getState(this.model));
    },
    updateState(state) {
      switch (state) {
        case 0:
          this.title = "其他补充";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加其他补充";
          } else if (this.state === 2) {
            this.title = "修改其他补充";
          }
          break;
        case 2:
          this.title = "其他补充";
          break;
      }
      this.state = state;
    },
  },
  watch: {
    dataSource: {
      handler(val) {
        this.model = this.transform(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>