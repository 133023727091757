<template>
  <div>
    <edit-block
      :showStatus="true"
      :status="status"
      :AuditStatus="dataSource.AuditStatus"
      :state="state"
      :title="title"
    >
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <StateEdit
        @cancel="cancel"
        @save="finish"
        :rules="rules"
        :model="model"
        slot="editing"
      />
      <!-- 已填写 -->
      <StateDisplay :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div class="display-opt" slot="display-opt">
        <a-space @click="updateState(1)" class="cursor-pointer text-primary">
          <img src="@/assets/icons/modify.svg" alt="" />
          <span>修改</span>
        </a-space>
      </div>
    </edit-block>
  </div>
</template>

<script>
import StateEdit from "@/components/user/state/editing.vue";
import StateDisplay from "@/components/user/state/display.vue";

export default {
  components: { StateDisplay, StateEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    AuditStatus:Number,
    save: Function,
    status: Boolean,
  },
  data() {
    return {
      title: "公司简介",
      model: {},
      rules: {},
      state: 0,
    };
  },
  methods: {
    transform({ Business, Team, Values }) {
      const temp = {
        Business,
        Team,
        Values,
      };
      this.updateState(this.getState(temp));
      this.validate(temp);
      return temp;
    },
    restore(obj) {
      return { ...obj };
    },
    getState({ Business, Team, Values }) {
      if (Business || Team || Values) {
        return 2;
      }
      return 0;
    },
    assign(obj) {
      const temp = { ...obj },
        origin = { ...this.dataSource };

      return Object.assign({}, origin, temp);
    },
    validate({ Business, Team, Values }) {
      let status = true;
      if (!Business || !Team || !Values) {
        status = false;
      }

      this.$emit("update:status", status);
    },
    finish(data) {
      const temp = this.assign(this.restore(data));
      this.save(temp).then(() => {
        this.$message.success("保存成功");
        this.model = this.transform(temp);
      });
    },
    cancel() {
      this.updateState(this.getState(this.model));
    },
    updateState(state) {
      switch (state) {
        case 0:
          this.title = "公司简介";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加公司简介";
          } else if (this.state === 2) {
            this.title = "修改公司简介";
          }
          break;
        case 2:
          this.title = "公司简介";
          break;
      }
      this.state = state;
    },
  },
  watch: {
    dataSource: {
      handler(val) {
        this.model = this.transform(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>