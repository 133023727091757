<template>
  <div class="p-20 bg-white">
    <a-space class="w-full" :size="20" direction="vertical">
      <State
        v-if="isTech"
        :dataSource="origin"
        :status.sync="status.state"
        :AuditStatus="origin.AuditStatus"
        :save="saveState"
      />
      <Compitive
        v-if="isTech"
        :AuditStatus="origin.AuditStatus"
        :dataSource="origin"
        :status.sync="status.compitive"
        :save="saveState"
      />
      <Partner
        v-if="isTech"
        :AuditStatus="origin.AuditStatus"
        :dataSource="origin"
        :save="saveState"
      />
      <Honor
        :AuditStatus="origin.AuditStatus"
        :dataSource="origin"
        :save="saveState"
      />
      <Patent
        :AuditStatus="origin.AuditStatus"
        :dataSource="origin"
        :save="saveState"
      />
      <Other
        v-if="isTech"
        :AuditStatus="origin.AuditStatus"
        :dataSource="origin"
        :save="saveState"
      />
      <div class="text-right">
        <a-button
          v-if="isManager && AuditStatus === 1"
          size="large"
          type="primary"
          @click="modal = true"
        >
          审核
        </a-button>
        <a-button
          v-else-if="!isManager && AuditStatus === 0"
          size="large"
          type="primary"
          @click="submitState"
          >提交</a-button
        >
      </div>
    </a-space>
    <a-modal
      v-model="modal"
      title="审核"
      okText="审核"
      cancelText="取消"
      @ok="evaluateRecoginze"
    >
      <a-form-model :model="evaluate">
        <a-form-model-item>
          <a-select v-model="evaluate.AuditStatus">
            <a-select-option :value="2">审核通过</a-select-option>
            <a-select-option :value="3">审核失败</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="evaluate.AuditStatus === 3">
          <gf-textarea
            :required="true"
            prop="Feedback"
            :max="300"
            :rows="5"
            v-model="evaluate.Feedback"
          ></gf-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import State from "./State.vue";
import Compitive from "./Compitive.vue";
import Partner from "./Partner.vue";
import Honor from "./Honor.vue";
import Patent from "./Patent.vue";
import Other from "./Other.vue";

import { saveCompanyRecoginze, getCompanyRecoginze } from "@/api";
import {
  getProCompany,
  evaluateProState,
  submitInfo,
  updateProCompany,
} from "@/api/company.js";
export default {
  components: {
    State,
    Compitive,
    Partner,
    Honor,
    Patent,
    Other,
  },
  data() {
    return {
      status: {
        state: false,
        compitive: false,
      },
      origin: {},
      evaluate: {
        AuditStatus: 2,
        Feedback: "",
      },
      AuditStatus: 0,
      modal: false,
      isTech: false,
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    isManager() {
      return this.role === 6 || this.role === 7;
    },
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    if (id && this.isManager) {
      this.getProState(id);
    } else {
      this.getState();
    }
  },
  methods: {
    getProState(id) {
      getProCompany(id).then(({ data }) => {
        this.isTech = data.IsTech;
        this.origin = data.Detail ? data.Detail.State || {} : {};
        this.ID = data.ID;
        this.AuditStatus = this.origin.AuditStatus;
      });
    },
    getState() {
      if (this.isManager) {
        this.getProState(this.ID);
      } else {
        getCompanyRecoginze().then(({ data }) => {
          this.origin = data.Detail ? data.Detail.State || {} : {};
          this.AuditStatus = this.origin.AuditStatus;
        });
      }
    },
    saveState(data) {
      if (this.isManager) {
        return updateProCompany(this.ID, { State: data }).then(() => {
          this.getState();
          return Promise.resolve();
        });
      } else {
        return saveCompanyRecoginze({ State: data }).then(() => {
          this.getState();
          return Promise.resolve();
        });
      }
    },
    submitState() {
      let status = true;
      for (let key in this.status) {
        if (!this.status[key]) {
          status = false;
        }
      }
      if (status) {
        submitInfo().then(() => {
          this.$message.success("提交成功");
          this.getState();
        });
      } else {
        this.$message.error("必填资料填写不完整");
      }
    },
    evaluateRecoginze() {
      evaluateProState(this.id, this.evaluate).then(() => {
        this.$message.success("提交成功");
        this.modal = false;
        this.getProState(this.id);
      });
    },
    validate() {},
  },
};
</script>