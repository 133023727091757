<template>
  <div>
    <edit-block :state="state" :title="title" :AuditStatus="AuditStatus">
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <HonorEdit
        @cancel="cancel"
        @save="finish"
        :model="model"
        :options="options"
        slot="editing"
      />
      <!-- 已填写 -->
      <HonorDisplay :options="options" :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div slot="display-opt" class="display-opt">
        <a-space @click="updateState(1)" class="cursor-pointer text-primary">
          <img src="@/assets/icons/modify.svg" alt="" />
          <span>修改</span>
        </a-space>
      </div>
    </edit-block>
  </div>
</template>

<script>
import HonorEdit from "@/components/user/honor/editing.vue";
import HonorDisplay from "@/components/user/honor/display.vue";

export default {
  components: { HonorDisplay, HonorEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    AuditStatus: Number,
    save: Function,
  },
  data() {
    return {
      title: "所获荣誉",
      model: {},
      rules: {},
      state: 0,
    };
  },
  inject: ["options"],
  methods: {
    transform({ Honors }) {
      const temp = { Honors };
      temp.Honors = Honors || [];
      this.updateState(this.getState(temp));

      return temp;
    },
    restore(obj) {
      return { ...obj };
    },
    getState({ Honors }) {
      if (Honors && Honors.length) {
        return 2;
      }
      return 0;
    },
    assign(obj) {
      const temp = { ...obj },
        origin = { ...this.dataSource };

      return Object.assign({}, origin, temp);
    },
    validate({ Honors }) {
      let status = true;
      status = !!Honors.length;
      this.$emit("update:status", status);
    },
    finish(data) {
      const temp = this.assign(this.restore(data));
      this.save(temp).then(() => {
        this.$message.success("保存成功");
        this.model = this.transform(temp);
      });
    },
    cancel() {
      this.updateState(this.getState(this.model));
    },
    updateState(state) {
      switch (state) {
        case 0:
          this.title = "所获荣誉";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加所获荣誉";
          } else if (this.state === 2) {
            this.title = "修改所获荣誉";
          }
          break;
        case 2:
          this.title = "所获荣誉";
          break;
      }
      this.state = state;
    },
  },
  watch: {
    dataSource: {
      handler(val) {
        this.model = this.transform(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>